import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Navigation } from "../../src/components/Navigation";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "publish-to-devops"
    }}>{`Publish to DevOps`}</h1>
    <p>{`I wanted to keep this part as short as possible, so I spent a ridiculous amount of time creating the following Visual Studio Code command:`}</p>
    <figure>
  <img src="/public/images/vindfang-demo-publish-project.png" title="Our Publish project to Azure DevOps command in Visual Studio Code" />
  <figcaption>
    <em>SKILL: Publish project to Azure DevOps</em> is the command we use to
    upload the project to DevOps.
  </figcaption>
    </figure>
    <figure>
  <img src="/public/images/vindfang-demo-publish-project-prompt-1.png" title="Choose the project to publish to, or create a new one" />
  <figcaption>
    Select the DevOps project to publish to from the list, or type a unique name
    to create a new project.
  </figcaption>
    </figure>
    <figure>
  <img src="/public/images/vindfang-demo-publish-project-prompt-2.png" title="Choose the name of the repository" />
  <figcaption>
    Give the DevOps repository a name. It suggests the name specified in{" "}
    <em>app.json</em>, as default.
  </figcaption>
    </figure>
    <h2 {...{
      "id": "magic-️"
    }}>{`Magic 🧙‍♂️`}</h2>
    <p>{`You should now see the DevOps repository pop up in your default browser:`}</p>
    <figure>
  <img src="/public/images/devops-repository.png" title="The Azure DevOps repository in the browser" />
  <figcaption>Everything is set up for you &mdash; permissions...</figcaption>
    </figure>
    <figure>
  <img src="/public/images/devops-pipeline.png" title="The Azure DevOps pipeline in the browser" />
  <figcaption>...build pipeline...</figcaption>
    </figure>
    <figure>
  <img src="/public/images/devops-artifacts.png" title="The Azure DevOps artifacts in the browser" />
  <figcaption>
    ...and distribution to the <em>Apps</em> feed.
  </figcaption>
    </figure>
    <p>{`That's it, you can continue working on your app in Visual Studio Code, without having to worry about Azure DevOps.
Smart, right? 😉`}</p>
    <Navigation previous="/my-first-extension/publish-app" next="/devops" mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      